<template>
	<div>
		<v-row>
			<v-col cols="12">
        <v-card>
          <v-card-text>
            <editor ref="editor" :modelValue.sync="markdown" :links="post.products[0].links" :textLength.sync="textLength" />
          </v-card-text>
        </v-card>
			</v-col>
    </v-row>
    <v-row>
			<v-col cols="4">
        <v-card>
          <v-card-text>
            <v-radio-group v-model="method">
              <v-radio value="sendPhoto" label="Фото сверху"></v-radio>
              <v-radio value="sendMessage" label="Фото снизу"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
			</v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <v-text-field v-model="post.products[0].button_text" placeholder="Текст кнопки" label="Текст кнопки" outlined></v-text-field>
          </v-card-text>
        </v-card>

      </v-col>
		</v-row>
	</div>
</template>

<script>
import _ from 'lodash'
import Editor from './Editor'
export default {
  name: 'ResultPost',
  components: {
    Editor,
  },
  props:{
    productLoaded: {type: Boolean, default: false},
    postMethod: String,
    post: {
      products: Array,
      finalText: String
    }
  },
  data(){
    return{
      method: this.postMethod,
      textLength: 0,
      markdown: '',
      editor: null,
      editorInited: false,
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline']
          ]
        },
        theme: 'snow',
        formats: ['bold', 'underline', 'italic']
      }
    }
  },
  computed:{
    needUpdateResultPost(){
      return this.$store.getters.needUpdateResultPost
    },
    needSaveResultPost(){
      return this.$store.getters.needSaveResultPost
    }
  },
  watch:{
    needUpdateResultPost(v){
      if (v){
        this.preUpdate()
      }
    },
    needSaveResultPost(v){
      if (v){
        this.$emit('update:finalText', this.clearHtml(this.markdown))
        this.$store.dispatch('needSaveResultPost', false)
      }
    },
    markdown(v){
      this.$emit('update:finalText', this.clearHtml(v))
    },
    method(v){
      this.$emit('update:postMethod', v)
    },
    textLength(v){
      if (v>1023){
        if (this.method !== 'sendMessage'){
          this.method = 'sendMessage'
        }
      }else{
        if (this.postMethod !== 'sendPhoto'){
          this.method = 'sendPhoto'
        }
      }
    }
  },
  methods:{
    clearHtml(v){
      if (typeof v === 'string'){
        v = v.replace(/<p[^>]*>/g, '<br>')
        v = v.replace(/<\/?p[^>]*>/g, '<br>')
        v = v.replace(/<strong[^>]*>/g, '<b>')
        v = v.replace(/<\/?strong[^>]*>/g, '</b>')
      }
      return v
    },
    preUpdate(){
      // небольшой костыль, чтобы компонент editor нормально реагировал на изменение markdown: сначала сделать его пустым, потом - заполнить. тогда editor должен нормально увидеть изменения.
      // this.markdown = ''
      // this.$refs.editor.setText('')
      this.updateMD()
    },
    updateMD(){
      console.log('updateMD inited...')
      if (!this.productLoaded){ // чтобы не дрочил текст, если продукт не выбран
        this.$store.dispatch('needUpdateResultPost', false)
        console.log('updateMD not inited, because product not loaded or canUpdateResultText is false')
        return
      }
      let res = ''
      _.each(this.post.products, (product, i) => {
        // res += '![](https://backend.telestatic.ru/upload/show?product_id=' + product.id + ')  \n'
        res += `<b>🔥${product.title}</b>`
        if (product.price > 0){
          res += ` за <b>${product.price}₽</b>`
        }
        if (product.fullPrice > 0){
          res += ` <s>(${product.fullPrice}₽)</s>`
        }
        res += ` ${this.getShop(product)}<br>`
        if (product.subtitle.length>0){
          res += '<br>' + product.subtitle + '<br>'
        }
        if (product.chars.length>0){
          res += '<br>'
          const chars = product.chars.split('\n')
          _.each(chars, (char, m) => {
            char = char.replace('•', '')
            res += '• ' + char + '<br>'
          })
        }
        if (product.description.length>0){
          res += '<br>' + product.description + '<br>'
        }
        if (product.rating.length>0){
          res += '<br>' + product.rating + '<br>'
        }
        if (product.warranty.length>0){
          res += '<br><b>' + product.warranty + '</b><br>'
        }
        if (product.delivery.length>0){
          res += '<br>' + product.delivery + '<br>'
        }
        if (product.promoCode.length>0){
          res += '<br><b>❗️Промокод:</b> ' + '<code>' + product.promoCode + '</code><br>'
        }
        // res += '\n' + '<a href="' + product.link + '">▶️Перейти к товару</a>\n'
        res += '<br>' + '<a href="{{URL_0}}">▶️Перейти к товару</a><br>'
        if (product.instruction.length>0){
          res += '<br>' + product.instruction + '<br>'
        }
        // if (product.otherPrice > 0){
        //   res += '<br>В других магазинах от ' + product.otherPrice + '₽.'
        // }
        //составляем последний блок с ценами
        const prices = product.prices
        let started = 0
        if (prices.lowest) {res += '<br>Самая низкая цена. '}
        if (prices.noName > 0){
          started = 1
          if (prices.lowest){
            res += `В "ноунейм" магазинах МСК от ${prices.noName}₽`
          }else{
            res += `<br>В "ноунеймах" МСК от ${prices.noName}₽`
          }
        } else if (prices.marketplaces > 0){
          started = 2
          if (prices.lowest){
            res += `На маркетплейсах РФ от ${prices.marketplaces}₽`
          }else{
            res += `<br>На маркетплейсах РФ от ${prices.marketplaces}₽`
          }
        } else if (prices.bigs > 0){
          started = 3
          if (prices.lowest){
            res += `В крупных сетевых РФ от ${prices.bigs}₽`
          }else{
            res += `<br>В крупных сетевых РФ от ${prices.bigs}₽`
          }
        } else if (prices.ozon > 0){
          started = 4
          if (prices.lowest){
            res += `На OZON от ${prices.ozon}₽`
          }else{
            res += `<br>На OZON от ${prices.ozon}₽`
          }
        } else if (prices.yandex > 0){
          started = 5
          if (prices.lowest){
            res += `На Яндекс.Маркете от ${prices.yandex}₽`
          }else{
            res += `<br>На Яндекс.Маркете от ${prices.yandex}₽`
          }
        } else if (prices.other > 0){
          started = 6
          if (prices.lowest){
            res += `В магазинах РФ от ${prices.other}₽`
          }else{
            res += `<br>В магазинах РФ от ${prices.other}₽`
          }
        }
        if (started){
          if (prices.marketplaces > 0 && started !== 2){
            res += `, на маркетплейсах РФ от ${prices.marketplaces}₽`
          } if (prices.bigs > 0 && started !== 3) {
            res += `, в крупных сетевых РФ от ${prices.bigs}₽`
          } if (prices.ozon > 0 && started !== 4){
            res += `, на OZON от ${prices.ozon}₽`
          } if (prices.yandex > 0 && started !== 5){
            res += `, на Яндекс.Маркете от ${prices.yandex}₽`
          } if (prices.other > 0 && started !== 6){
            res += `, в магазинах РФ от ${prices.other}₽`
          }
          // if (product.shop.id === 1 || product.shop.id === 2) { //Ali, Tmall
          //   res += ' <i>(везде без учета доставки)</i>' //Артур попросил убрать 08.04.22
          // }
          if (prices.without_delivery){
            res += ' <i>(везде без учета доставки)</i>'
          }
        }
        if (product.shop) {
          if (product.shop.id === 1 || product.shop.id === 2) { //Ali, Tmall
            res += '<br><br>Реклама ООО АЛИБАБА.КОМ (РУ) ИНН 7703380158'
          }
        }
        console.log('product: ', product)
      })
      this.markdown = res
      console.log('before update: ', JSON.parse(JSON.stringify(this.post.products[0].finalText)))
      console.log('updating to: ', res)
      this.$store.dispatch('needUpdateResultPost', false)
    },
    setText(text, lock = false, force = false){
      this.$refs.editor.setText(text, lock, force)
    },
    toggleBold(){
      if (this.editorInited){
        this.$refs.markdownEditor.simplemde.toggleBold()
      }
    },
    toggleItalic(){
      if (this.editorInited){
        this.$refs.markdownEditor.simplemde.toggleItalic()
      }
    },
    getShop(product){
      if (_.has(product.shop, 'preposition') && _.has(product.shop, 'short_name')){
        return product.shop.preposition + ' ' + product.shop.short_name || ''
      }
      return ''
    },
  }
}
</script>

<style lang="scss">
	/* Basic editor styles */
	.ProseMirror {
		> * + * {
			margin-top: 0.75em;
		}

		code {
			background-color: rgba(#616161, 0.1);
			color: #616161;
		}
	}

	.content {
		padding: 1rem 0 0;

		h3 {
			margin: 1rem 0 0.5rem;
		}

		pre {
			border-radius: 5px;
			color: #333;
		}

		code {
			display: block;
			white-space: pre-wrap;
			font-size: 0.8rem;
			padding: 0.75rem 1rem;
			background-color:#e9ecef;
			color: #495057;
		}
	}
</style>
